import React, { useState, useEffect } from 'react';

import '../../style/App.css';

const ShiftForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    signInTime: '',
    signOutTime: '',
    date: new Date().toISOString().split('T')[0]
  });

  // State to hold the pre-populated names from localStorage
  const [prePopulatedNames, setPrePopulatedNames] = useState([]);

  useEffect(() => {
    // Retrieve names from localStorage when the component mounts
    const shiftManagementData = JSON.parse(localStorage.getItem('shiftManagement')) || [];
    const names = shiftManagementData.map(entry => entry.employee.name) || [];

    setPrePopulatedNames(names);
  }, []);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const calculateHoursWorked = () => {
    const signIn = new Date(`2023-01-01 ${formData.signInTime}`);
    const signOut = new Date(`2023-01-01 ${formData.signOutTime}`);
    const timeDiff = signOut - signIn;

    const hours = Math.floor(timeDiff / (1000 * 60 * 60));
    const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));

    return `${hours} H ${minutes} M`;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const hoursWorked = calculateHoursWorked();
    const currentDate = new Date().toLocaleDateString();

    const shiftData = {
      ...formData,
      hoursWorked,
      currentDate
    };

    const shifts = JSON.parse(localStorage.getItem('shifts')) || [];
    shifts.push(shiftData);
    localStorage.setItem('shifts', JSON.stringify(shifts));

    setFormData({
      name: '',
      signInTime: '',
      signOutTime: '',
      date: new Date().toISOString().split('T')[0]
    });
  };

  return (
    <div className='employee-form'>
      <h2>Shift Form</h2>
      <form onSubmit={handleSubmit}>
        <label>
          Name:
          <select
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          >
            <option value="">Select a name</option>
            {prePopulatedNames.map((name, index) => (
              <option key={index} value={name}>
                {name}
              </option>
            ))}
          </select>
        </label>
        <br />
        <label>
          Sign In Time:
          <input
            type="time"
            name="signInTime"
            value={formData.signInTime}
            onChange={handleChange}
            required
          />
        </label>
        <br />
        <label>
          Sign Out Time:
          <input
            type="time"
            name="signOutTime"
            value={formData.signOutTime}
            onChange={handleChange}
            required
          />
        </label>
        <br />
        <button type="submit">Save</button>
      </form>
    </div>
  );
};

export default ShiftForm;
