import React from 'react';

import Navbar from '../components/nav';
import ShiftForm from './pages/shift-mgt-manual';
import { ShiftList } from '../components/table';

import '../style/App.css';

const Shifts = () =>{
    return (
        <>
        <Navbar />
         <div className='container'>
            <ShiftForm />
            <ShiftList />
         </div>
        </>
    )
}

export default Shifts;
