import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS } from "chart.js/auto";

const ShiftChart = () => {
  const [shifts, setShifts] = useState([]);

  useEffect(() => {
    // Retrieve data from localStorage when the component mounts
    const storedShifts = JSON.parse(localStorage.getItem('shifts') || []);
    setShifts(storedShifts);
  }, []); // The empty dependency array ensures this effect runs once when the component mounts


  // Extracting data for the chart
  const chartData = {
    labels: shifts.map(shift => shift.name),
    datasets: [
      {
        label: 'Hours Worked',
        backgroundColor: 'rgba(75,192,192,0.4)',
        borderColor: 'rgba(75,192,192,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(75,192,192,0.6)',
        hoverBorderColor: 'rgba(75,192,192,1)',
        data: shifts.map(shift => shift.hoursWorked.substring(0, 2))
      }
    ]
  };

  const chartOptions = {
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Total Hours Worked'
        }
      },
      x: {
        title: {
          display: true,
          text: 'Employee'
        }
      }
    }
  };

  return (
    <div>
      <h2>Shift Chart</h2>
      <Bar data={chartData} options={chartOptions} />
    </div>
  );
};

export default ShiftChart;
