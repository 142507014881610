import React from 'react';

import Navbar from '../components/nav';
import EmployeeForm from './pages/employee';
import { EmployeeList } from '../components/table';

import '../style/App.css';

const Employees = () =>{
    return(
        <>
            <Navbar />
            <div className='container'> 
            <EmployeeForm />
            <EmployeeList />
             </div>
        </>
    
    )
}

export default Employees;
