import React, { useState } from 'react';

import '../../style/App.css';

const EmployeeForm = () => {
  const [employee, setEmployee] = useState({
    name: '',
    dateOfBirth: '',
    department: '',
    salary: ''
  });

  const handleEmployeeChange = (e) => {
    const { name, value } = e.target;
    setEmployee((prevEmployee) => ({
      ...prevEmployee,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Save entered employee details in local storage
    const storedData = JSON.parse(localStorage.getItem('shiftManagement')) || [];
    storedData.push({ employee });
    localStorage.setItem('shiftManagement', JSON.stringify(storedData));

    // Reset the form
    setEmployee({
      name: '',
      dateOfBirth: '',
      department: '',
      salary: ''
    });
  };

  return (
    <div className='employee-form'>
      <h1>Employee Information</h1>
      <form onSubmit={handleSubmit}>
      <label>
          Name:
          <input
            type="text"
            name="name"
            value={employee.name}
            onChange={handleEmployeeChange}
            required
          />
        </label>
        <br />

        <label>
          Date of Birth:
          <input
            type="date"
            name="dateOfBirth"
            value={employee.dateOfBirth}
            onChange={handleEmployeeChange}
            required
          />
        </label>
        <br />

        <label>
          Department:
          <input
            type="text"
            name="department"
            value={employee.department}
            onChange={handleEmployeeChange}
            required
          />
        </label>
        <br />

        <label>
          Salary:
          <input
            type="text"
            name="salary"
            value={employee.salary}
            onChange={handleEmployeeChange}
            required
          />
        </label>
        <br />

        <button type="submit"> Save </button>
      </form>

    </div>
  );
};

export default EmployeeForm;
