import React, { useEffect, useState } from 'react';

import '../style/App.css';

export const EmployeeList = () => {
  const [employees, setEmployees] = useState([]);

  useEffect(() => {
    // Retrieve stored employee data from local storage
    const storedData = JSON.parse(localStorage.getItem('shiftManagement')) || [];
    setEmployees(storedData);
  }, []);

  return (
    <div>
      <h1>Employee List</h1>
      <table className='employee-table'>
        <thead>
          <tr>
            <th>Name</th>
            <th>Date of Birth</th>
            <th>Age</th>
            <th>Salary</th>
            <th>Department</th>
          </tr>
        </thead>
        <tbody>
          {employees.map((entry, index) => (
            <tr key={index}>
              <td>{entry.employee.name}</td>
              <td>{entry.employee.dateOfBirth}</td>
              <td>{entry.employee.age}</td>
              <td>{entry.employee.salary}</td>
              <td>{entry.employee.department}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};


export const ShiftList = () => {
  const [shifts, setShifts] = useState([]);

  useEffect(() => {
    // Retrieve data from localStorage when the component mounts
    const storedShifts = JSON.parse(localStorage.getItem('shifts')) || [];
    setShifts(storedShifts);
  }, []); // The empty dependency array ensures this effect runs once when the component mounts

  const formatDate = (dateString) => {
    const options = { day: 'numeric', month: 'short', year: 'numeric' };
    return new Intl.DateTimeFormat('en-US', options).format(new Date(dateString));
  };

  return (
    <div>
      <h2>Shift List</h2>
      <table className='employee-table'>
        <thead>
          <tr>
            <th>Name</th>
            <th>Sign In Time</th>
            <th>Hours Worked</th>
            <th>Sign Out Time</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
          {shifts.map((shift, index) => (
            <tr key={index}>
              <td>{shift.name}</td>
              <td>{shift.signInTime}</td>
              <td>{shift.hoursWorked}</td>
              <td>{shift.signOutTime}</td>
              <td>{formatDate(shift.date)}</td> {/* Format the date here */}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
