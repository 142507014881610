import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { Provider } from 'react-redux'

// import Navbar from './components/nav';
import Employees from './app/employees';
import Shifts from './app/shifts';
import PerformanceAnalytics from './app/performance';

import './index.css';
// import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './state/store'


const router = createBrowserRouter([
  {
    path: "/",
    element: <Employees />
  },
  {
    path: "shifts",
    element: <Shifts />,
  },
  {
    path: "performance",
    element: <PerformanceAnalytics />,
  },
]);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store} >
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
