// EarningsChart.js
import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS } from "chart.js/auto";

const EarningsChart = () => {
  const [earningsData, setEarningsData] = useState([]);
  const [ salary, setSalary ] = useState([])
  const [hoursWorked, setHoursWorked] = useState([])

  useEffect(() => {
    // Fetch salary data from localStorage
    const salaryData = JSON.parse(localStorage.getItem('shiftManagement')) || [];

    // console.log(salaryData[0])

    // Fetch hoursWorked data from localStorage
    const hoursWorkedData = JSON.parse(localStorage.getItem('shifts')) || [];
    // console.log(hoursWorkedData[0].hoursWorked.substring(0, 2))

    setSalary(salaryData)
    setHoursWorked(hoursWorkedData)
  }, []); // Run the effect only once on mount

  const employeeSalaryData = {
    labels: salary.map(employee => employee.employee.name),
    datasets: [
      {
        label: 'Earnings',
        fill: true,
        lineTension: 0.1,
        backgroundColor: 'rgba(75,192,192,0.4)',
        borderColor: 'rgba(75,192,192,1)',
        borderWidth: 2,
        data: hoursWorked.map(employee => employee.hoursWorked.substring(0, 2) * 100)
      },
    ],
  };

  return (
    <div>
      <h2>Earnings Chart</h2>
      <Line data={employeeSalaryData} />
    </div>
  );
};

export default EarningsChart;
