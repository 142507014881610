import React from 'react';

import Navbar from '../components/nav';
import ShiftChart from './pages/workedChart';
import EarningsChart from './pages/earnings';


import '../style/App.css';

const PerformanceAnalytics = () =>{
    return (
        <>
        <Navbar />
        <div className='container'>
            <ShiftChart />
            <EarningsChart />
        </div>
        </>
    )
}

export default PerformanceAnalytics;
