import React from 'react';
import { Link } from 'react-router-dom';

import '../style/components.css'

const Navbar = () => {
  return (
    <nav className='navbar'>
      <ul className='navbar-list'>
        <li className='navbar-item'>
          <Link
            to="/">Employees</Link>
        </li>
        <li className='navbar-item'>
          <Link
            to="/shifts">Shifts</Link>
        </li>
        <li className='navbar-item'>
          <Link
            to="/performance">Performance</Link>
        </li>
      </ul>
    </nav>

  );
};

export default Navbar;
